//
// Custom
//

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --morado: #652b91;
  //--morado: #2563b0;
  --morado-bajo: #b892b7;
  //--morado-bajo: #c3d7f3;
  --naranja: #e88d21;
  --naranja-bajo: #f9ebdb;
  --rojo: #ee2737;
  --rojo-bajo: #f38989;
  --verde: #43b02a;
  --verde-success: #8bc34a;
  --negro: #232a35;
  --gris: #f2f2f2;
  --rosa: #ff0066;
  --azul: #0171c0;


  --gris-fuerte: #a5a5a5;
  --gris-placeholder: #bfbfbf;
  --background-content: #fcfcfc;

  --alpha-naranja: rgba(232, 142, 32, 0.2);
  --alpha-verde: rgba(67, 176, 50, 0.2);
  --alpha-morado: rgba(149 147 217, 0.34);
  //--alpha-morado: #c3d7f3;
  --alpha-rojo: rgba(238, 39, 55, 0.2);
}


.m0 {
  margin: 0px !important;
}

.p0 {
  padding: 0px !important;
}

.mh-1 {
  margin-left: 1px;
  margin-right: 1px
}

.mh-2 {
  margin-left: 2px;
  margin-right: 2px
}

.mh-3 {
  margin-left: 3px;
  margin-right: 3px
}

.mh-4 {
  margin-left: 4px;
  margin-right: 4px
}

.mh-5 {
  margin-left: 4px;
  margin-right: 5px
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px
}

.mv-1 {
  margin-top: 1px;
  margin-bottom: 1px
}

.mv-2 {
  margin-top: 2px;
  margin-bottom: 2px
}

.mv-3 {
  margin-top: 3px;
  margin-bottom: 3px
}

.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px
}

.mv-5 {
  margin-top: 4px;
  margin-bottom: 5px
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px
}

.mr-5 {
  margin-right: 5px
}

.mr-10 {
  margin-right: 10px
}

.mr-15 {
  margin-right: 15px
}

.mr-20 {
  margin-right: 20px
}

.ml-5 {
  margin-left: 5px
}

.ml-10 {
  margin-left: 10px
}

.ml-20 {
  margin-left: 20px
}

.mb-5 {
  margin-bottom: 5px
}

.mb-10 {
  margin-bottom: 10px
}

.mb-15 {
  margin-bottom: 15px
}

.mb-20 {
  margin-bottom: 20px
}

.mb-30 {
  margin-bottom: 30px
}

.mt-5 {
  margin-top: 5px
}

.mt-10 {
  margin-top: 10px
}

.mt-15 {
  margin-top: 15px
}

.mt-20 {
  margin-top: 20px
}

.f12 {
  font-size: 12px
}

.f13 {
  font-size: 13px
}

.f14 {
  font-size: 14px
}

.f15 {
  font-size: 15px
}

.f16 {
  font-size: 16px
}

.f17 {
  font-size: 17px
}

.f18 {
  font-size: 18px
}

.f20 {
  font-size: 20px
}

.f22 {
  font-size: 22px
}

.f24 {
  font-size: 24px
}

.f30 {
  font-size: 30px
}

.f35 {
  font-size: 35px
}

.f40 {
  font-size: 40px
}

.negrita {
  font-weight: 600
}

.br8 {
  border-radius: 8px
}

.br10 {
  border-radius: 10px
}

.br12 {
  border-radius: 12px
}

.br15 {
  border-radius: 15px
}

.br25 {
  border-radius: 25px
}

.br50 {
  border-radius: 50%
}

.pd5 {
  padding: 5px
}

.pd10 {
  padding: 10px
}

.pd15 {
  padding: 15px
}

.pd20 {
  padding: 20px
}

//LINE HEIGHT
.lh-16 {
  line-height: 16px
}

.lh-18 {
  line-height: 18px
}

.lh-20 {
  line-height: 20px
}

.lh-22 {
  line-height: 22px
}

//CLASS
.bg-naranja {
  background-color: var(--naranja);
}

.bg-naranja-bajo {
  background-color: var(--naranja-bajo);
}

.bg-verde {
  background-color: var(--verde);
}

.bg-verde-success {
  background-color: var(--verde-success)
}

.bg-rosa {
  background-color: var(--rosa);
}

.bg-azul {
  background-color: var(--azul);
}

.bg-rojo {
  background-color: var(--rojo);
}


.bg-morado {
  background-color: var(--morado)
}

.color-naranja {
  color: var(--naranja)
}

.color-verde {
  color: var(--verde)
}

.color-verde-success {
  color: var(--verde-success)
}

.color-morado {
  color: var(--morado);
}

.color-gris-fuerte {
  color: var(--gris-fuerte) !important;
}

.color-rojo {
  color: var(--rojo);
}

.color-rojo-bajo {
  color: var(--rojo-bajo);
}

.color-white {
  color: white;
}

.color-negro {
  color: var(--negro)
}


.alpha-white {
  background-color: rgb(242, 242, 242, 40) !important;
}

.alpha-naranja {
  background-color: var(--alpha-naranja) !important;;
}

.alpha-verde {
  background-color: var(--alpha-verde) !important;
}

.alpha-morado {
  background-color: var(--alpha-morado) !important;;
}

.alpha-rojo {
  background-color: var(--alpha-rojo) !important;;
}


//EDAD
.container-status {
  width: 100%;
  border: 1px solid var(--gris);
  padding: 0px 12px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
}

.container-status .status-value {
  margin: 0px 5px;
}

.container-status .status-tag {
  padding: 4px 8px;
  color: white;
  border-radius: 8px;
}

.container-status.alpha-verde .status-value {
  color: var(--verde)
}

.container-status.alpha-verde .status-tag {
  background-color: var(--verde)
}

.container-status.alpha-naranja .status-value {
  color: var(--naranja)
}

.container-status.alpha-naranja .status-tag {
  background-color: var(--naranja)
}


/*OVERLAY LOADER*/
//.overlay {
//	left: 0;
//	top: 0;
//	width: 100%;
//	height: 100%;
//	position: fixed;
//	background: var(--color-gris-dark);
//	z-index: 999999999;
//}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: var(--morado);
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/*OVERLAY LOADER*/


.punto {
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 50%;
  display: block;
}


.img-icon-sm {
  width: 15px;
  height: 15px;
}

.img-icon {
  width: 18px;
  height: 18px;
}

.img-icon-md {
  width: 24px;
  height: 24px;
}

.badge-md {
  width: fit-content;
  height: 30px;
  padding: 5px 12px;
  border-radius: 12px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-sm {
  width: fit-content;
  height: 22px;
  padding: 5px 12px;
  border-radius: 12px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}


//CUSTOM CSS TEMPLATE
.in-error {
  display: none;
  color: #ff5722;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}

.bg-primary {
  background-color: var(--morado);
}

.btn-primary {
  background-color: var(--morado) !important;
  border-color: var(--morado) !important;
}

.progress-bar {
  background-color: var(--morado) !important;
}

.btn-wizard {
  background-color: var(--morado);
  color: white;
  padding: 1rem 2rem;
  border-radius: 16px;
}

.btn-wizard:hover {
  background-color: var(--morado);
  color: white;
}

.wizard-progress .wizard-progress-btn {
  width: 2.6rem;
  height: 2.6rem;
}

.btn-step:after {
  position: absolute;
  content: attr(data-title);
  left: -84px;
  top: 44px;
  width: 200px;
  height: 1.25rem;
  /* background-color: blue; */
  color: #2563b0;
  font-size: 14px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

//HACER MAS chico el progress
.wizard-progress {
  width: 65%;
  margin: 30px auto 80px auto;
}


#modal-qr .modal-sm {
  max-width: 380px !important;
}

#modal-qr .modal-content {
  background: #f2f2f2;
}


.btn-back-wizard {
  color: var(--morado-bajo);
}

// radios, checks
.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -2em;
}

.icon-search {
  color: var(--morado) !important;
}

#searchInput::placeholder {
  color: var(--gris-fuerte) !important;
  font-weight: normal !important;
  font-family: "Poppins";
}

.content {
  background-color: var(--background-content);
}


.breadcrumb-sub {
  display: flex;
  align-items: center;
}

.breadcrumb-title {
  font-size: 30px;
  font-weight: 600;
  margin: 0px;
}

.breadcrumb-subtitle {
  color: #d9d9d9;
}

.breadcrumb-point {
  color: transparent;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 5px;
  background-color: #d9d9d9
}


.custom-card {

}

.custom-card {
  box-shadow: none;
}

.custom-card .card-header,
.custom-card .card-body,
.custom-card .card-footer {
  background-color: var(--background-content) !important;
  box-shadow: none;
}


.scroll-margin {
  scroll-margin-top: map-get($header, height) + map-get($subheader, height) +
		map-get($page, padding-y);
}


.bg-login {
  //background-image: url(../../assets/img/fondos/bg-login.png);
  background-size: cover;
  //background-color: #9593D9;
  background: #9e7cb7;
  background: -webkit-linear-gradient(90deg, #652b91,#b08dd3,#652b91);/* Chrome 10-25, Safari 5.1-6 */                          background: linear-gradient(90deg, #652b91,#b08dd3,#652b91);/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


.glass {
  background: rgba(255, 255, 255, 0.18);
  //box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 50px;
  border: 1px solid #272932;
}


.form-login .form-control:focus {
  border-color: var(--gris) !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.in-login {
  background-color: rgb(218, 213, 213) !important;
  //box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );


  border-radius: 18px;

  border: 1px solid rgba(255, 255, 255, 0.15);
}

#login-password {
  color: #272932;
}


.form-login label {
  color: #272932 !important;
  font-family: 'Poppins';
}

.button-login {
  background: #652b91;
  color: #ffffff !important;
  border-radius: 18px !important;

}

.button-recovery {
  background: #ffffff;
  color: #333333 !important;

}

.button-secondary {
  background: #652b91;
  color: #ffffff !important;
  border-radius: 18px !important;

}


//MENU
.aside {
  //background-color: #2563b0 !important;
  //background-color: #9593D9 !important;
}

.aside .navigation-link:hover {
  background-color: #f2f2f233;
  color: #e7eef8;
}

.aside .navigation-link.active {
  background-color: #f2f2f233;
  color: #e7eef8;
}


//CSS CARDS

.custom-wizard.card {
  box-shadow: none;
}

.custom-wizard .card-header,
.custom-wizard .card-body,
.custom-wizard .card-footer {
  background-color: var(--background-content) !important;
  box-shadow: none;
}


//CSS FORM

.btn-danger {
  background-color: #ee2737 !important;
  border-color: #ee2737 !important;
}

img.bg-l25-primary {
  background-color: white !important;
}

//.css-yk16xz-control {
.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  border-color: #f2f2f2 !important;
  border-radius: 15px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 50px !important;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

//.css-1pahdxg-control {
.css-t3ipsp-control {
  min-height: 50px !important;
  background-color: white;
  border-color: #f2f2f2 !important;
  border-radius: 15px !important;
  outline: 0 !important;

}

//.css-2b097c-container input {
.css-b62m3t-container input {
  outline: none !important;
}

//.css-2b097c-container input:focus {
.css-b62m3t-container input:focus {
  border-color: var(--gris) !important;

}

.form-control {
  box-shadow: none !important;
}

.form-control:focus {
  border-color: var(--gris) !important;
  background-color: white !important;
}

.span-required {
  color: var(--rojo);
}

.label-form {
  color: var(--gris-fuerte);
  font-size: 12px;
  margin-bottom: 8px;
}

.custom-input {
  width: 100%;
  background-color: white;
  border: 1px solid var(--gris);
  padding: 0px 12px;
  height: 50px;
  border-radius: 15px;
  outline: none;

}

.custom-area {
  width: 100%;
  background-color: white;
  border: 1px solid var(--gris);
  padding: 0px 12px;
  border-radius: 15px;

}

.custom-input::placeholder {
  color: var(--gris-placeholder);
}

textarea {
  padding: 20px !important;
  outline: none !important;
}


.custom-wizard .row {
  margin-bottom: 30px;
}

.container-protocolos .row {
  margin-bottom: 30px;
}

.container-protocolos {
  padding: 20px;
  //margin-top: 10px;
}

.container-protocolos .container-paso .row {
  margin-bottom: 20px !important;
}


.radios-sexo {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}


//EXPEDIENTES CLINICOS

.generales {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabla-expedientes .badge {
  margin: 0px 4px;
  min-width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}


.btn-crear-reporte {
  //background-color: rgba(242,242,242,.6);
  border-radius: 15px;
  //width: 88%;
  margin: auto;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}


//PROTOCOLOS MEDICOS
.btn-registrar-protocolo {
  cursor: pointer;
  color: white;
  border-radius: 15px;
  padding: 10px;
  font-weight: 600;
}

.card-protocolo {

}

.card-protocolo .folio {
  color: #a5a5a5;
  font-size: 12px;
}

.card-protocolo .nombre {
  color: var(--morado);
  font-size: 16px;
  font-weight: 600;
}

.card-protocolo .pasos {
  font-weight: 600;
}

.card-protocolo .descripcion {
  color: #a5a5a5;
}


.respuestas p {

}


//DETALLE PACIENTE
.btn-tab {
  padding: 8px 15px;
  color: var(--negro);
  font-weight: 600;
  margin: 0px 10px;
  cursor: pointer;
}

.btn-tab:hover {
  color: var(--morado);
}

.btn-tab.active {
  color: var(--morado);
}


.card-info {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
}

.card-info .content-title {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.row-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.card-info .tag {
  color: var(--gris-fuerte);
}

.card-info .val {
  color: var(--negro);
}


.card-medico {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
}

.card-medico .content-title {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.row-medico {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.card-medico .tag {
  color: var(--gris-fuerte);
}

.card-medico .val {
  color: var(--negro);
}

.generales .badge {
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge.edad {
  width: 60px;
  height: 25px;
  font-weight: normal;
}

.badge.sexo {
  width: 50px;
  height: 25px;
}

.badge.tipo {
  width: 60px;
  height: 25px;
}

.foot {
  border-top: 1px solid var(--gris);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}


//Expedientes
.tabla-expedientes th, .tabla-expedientes td {
  text-align: center;
}


.a-link {
  color: black;
  text-decoration: none !important;
}

.a-link:hover {
  color: black;
}


.d-flex-center {
  display: flex;
  align-items: center;
}


.modal-eliminar {

}

.modal-eliminar .content-modal {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.modal-eliminar .title-modal {
  font-size: 22px;
  font-weight: 600;
}

.msg-modal-eliminar {
  font-size: 14px;
  text-align: center;
}

.navigation-link-info svg {
  margin: 0;
}


.rbc-toolbar-label {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: #c3d7f3 !important;;
}


.full-video {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
}

.modalTransmision {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 999999999;
  pointer-events: none;
}

.modalTransmision .contenido {
  position: fixed;
  width: 60%;
  max-width: 1200px;
  min-height: 300px;
  padding: 5px;
  border: 1px solid #000000;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
}

#offcanvas {
  z-index: 9999999999;
}

.color-charcoal {
  color: #272932 !important;
}

html, body, input, select, textarea {

  font-family: 'Figtree', sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5 {
  font-family: 'League Spartan', sans-serif !important;
}


.container-contacto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.contacto-titulo {
  font-weight: 600;
  font-size: 16px;
}


.container-contacto .form-check-input {
  width: 3rem;
  margin-right: 8px;
}

.accent-white {
  --bs-table-accent-bg: '#fcfcfc' !important;
}

.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker__month-select{
  border:1px solid #e2e2e2;
  border-radius: 4px;
  padding: 2px 6px;
}
.react-datepicker__year-select{
  border:1px solid #e2e2e2;
  border-radius: 4px;
  padding: 2px 6px;
}

#modal-image .modal-dialog{
  background-color: transparent;

}

#modal-image .modal-content{
  background-color: rgba(0, 0, 0, 0.81);
  -webkit-box-shadow:none;
  box-shadow: none;

}

.btnClose{
  background: #1f21283d;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
  cursor: pointer;
}
