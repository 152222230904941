//
//  Page Wrapper
//
.page-wrapper {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
}


.page-wrapper .page {
	z-index: 1;
}